<!--生产发货运输统计列表-->
<template>
    <div class="productionDeliveryTransport">
        <currencyTable :table-data="tableData" ref="currencyTable">
            <div slot="queryCriteria" class="queryCriteria">
                <div class="queryList">
                    <h4>场站：</h4>
                    <el-select v-model="tableData.requestParameter.station_id" placeholder="请选择">
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="queryList">
                    <h4>时间段：</h4>
                    <el-date-picker
                        class="time-range-inp"
                        v-model="datetimerange"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </div>
                <div class="queryList">
                    <el-input v-model="tableData.requestParameter.search" placeholder="请输入搜索内容"></el-input>
                </div>
                <div class="btns">
                    <el-button size="mini" @click="query">
                        查询
                    </el-button>
                </div>
            </div>
        </currencyTable>
    </div>
</template>

<script>
import currencyTable from '@/components/table/index.vue';
export default {
    name: 'production-delivery-transport',
    components: { currencyTable },
    data() {
        return {
            station_id: '',
            stationList: '', // 场站列表
            datetimerange: '', // 时间选择值
            tableData: {
                headerData: [{
                    field: 'sjxm',
                    title: '司机姓名',
                    width: 150,
                }, {
                    field: 'shch',
                    title: '车号',
                }, {
                    field: 'plate_number',
                    title: '车牌号',
                }, {
                    field: 'logistics_company_name',
                    title: '运输公司',
                }, {
                    field: 'trans_nums',
                    title: '运输趟次',
                }, {
                    field: 'volume',
                    title: '运输方量',
                }, {
                    field: 'sign_volume',
                    title: '签收方量',
                }],
                isShowNo: true,
                generalQuery: false,
                requestUrl: '/production/productionexternal/tankcartrans/statistics',
                requestMethod: 'post',
                requestParameter: {
                    station_id: '',
                    start_date: '',
                    end_date: '',
                    search: '',
                },
            },
        };
    },
    watch: {
        station_id(val) {
            this.tableData.requestParameter.station_id = val;
        },
        datetimerange(val) {
            if (val) {
                this.tableData.requestParameter.start_date = val[0];
                this.tableData.requestParameter.end_date = val[1];
            } else {
                this.tableData.requestParameter.start_date = '';
                this.tableData.requestParameter.end_date = '';
            }
        },
    },
    created() {
        this.getStationList();
    },
    methods: {
        // 获取场站列表
        getStationList() {
            const Uid = this.$takeTokenParameters('Uid');
            this.$axios
                .get('/interfaceApi/baseinfo/stationmanger/get_station_user?userId=' + Uid)
                .then(data => {
                    if (data.length > 0) {
                        this.stationList = data;
                    }
                })
                .catch(error => {
                    this.$message.error(error.TypeError);
                });
        },
        query() {
            this.$refs.currencyTable.getTableData();
        },
    },
};
</script>

<style lang="stylus" scoped>
 .productionDeliveryTransport
     .queryCriteria
         float left
         display flex
         .queryList
             margin-right: 0.15rem
             display flex
             flex 1
             h4
                 white-space nowrap
                 line-height: 0.36rem
                 font-size: 0.16rem
             .btns
                 font-size: 0.14rem;
                 height: 0.36rem;
                 line-height: 0.36rem;
                 margin: 0.05rem 0;
                 color: #fff;
                 border: none;
                 background: #2978ff;
                 width: 0.66rem;
                 border-radius: 2px;
                 cursor: pointer;
                 margin-right: 0.2rem;
             .time-range-inp
                 width 3.8rem!important;
                 height 0.36rem
                 >>> .el-range__close-icon
                    display flex
                    align-items center
</style>
